import React from "react"
import { graphql } from "gatsby"
import Link from "../components/HelperComponents/TransitionLink"
import useParser from "../components/HelperComponents/useParser"

import SEO from "../components/seo"
import ContactFooter from "../components/ContactForm/ContactFooter"

import mainStyle, {
  sectionStyle,
  mainTextStyle,
} from "../components/styles/mainStyle"

const DienstPage = ({ data }) => {
  const { naam_dienst, uitgebreid, meta_description } = data.prismicDienstI.data
  const {
    orange,
    main_color_two,
    main_color_three,
  } = data.prismicBasicInfo.data

  const parsedHTML = useParser(uitgebreid.html)

  return (
    <>
      <SEO title={naam_dienst.text} description={meta_description.text} />

      <main
        className="borderradius-div-bottom"
        style={{ ...mainStyle, backgroundColor: main_color_two }}
      >
        <div style={sectionStyle}>
          <section
            id="uitgebreide-text"
            style={mainTextStyle}
            children={parsedHTML}
          />

          <div style={{ textAlign: "center" }}>
            <Link
              to="/diensten/"
              linkStyle={{
                display: "inline-block",
                color: main_color_three,
                textDecoration: "none",
                backgroundColor: orange,
                padding: 15,
                margin: "25px 0 75px",
                fontWeight: "bold",
                borderRadius: "5px 25px 5px 5px",
              }}
            >
              alle diensten
            </Link>
          </div>
        </div>
      </main>

      <ContactFooter />
    </>
  )
}

export default DienstPage

export const dienstQuery = graphql`
  query dienstBySlug($uid: String!) {
    prismicDienstI(uid: { eq: $uid }) {
      uid
      data {
        naam_dienst {
          text
        }
        uitgebreid {
          html
        }
        meta_description {
          text
        }
      }
    }
    prismicBasicInfo {
      data {
        orange
        main_color_two
        main_color_three
      }
    }
  }
`
